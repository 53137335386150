import { isNud } from '@/shared/utilities/typing'
import { dateYmdHi } from '@/shared/utilities/date-format'

/**
 * お知らせ エンティティ
 */
class Information {
    information_id;
    published_date;
    content;
    link_url;
    link_label;
    is_published;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.information_id = properties.information_id;
        this.published_date = properties.published_date;
        this.content = properties.content;
        this.link_url = properties.link_url;
        this.link_label = properties.link_label;
        this.is_published = properties.is_published ?? 0;
    }

    get excerpt() {
        return this.content.substr(0, 30);
    }

    get published_date_display() {
        return dateYmdHi(this.published_date);
    }

    get is_published_display() {
        return this.is_published ? "公開" : "非公開";
    }
}

export default Information;
