<template>
    <section class="section">
    <div class="mb-3 d-flex align-items-center justify-content-between">
        <h1 class="mb-3"><i class="bi bi-chat-right-text text-primary"></i> お知らせ</h1>
        <template v-if="extension && extension.link_url && extension.link_label">
            <a :href="extension.link_url" class="btn btn-primary">{{ extension.link_label }}</a>
        </template>
    </div>

    <inline-loader v-if="loading"></inline-loader>
    <template v-else>
    <template v-if="informations.length">
        <div class="card mb-3" v-for="information of informations" :key="information.information_id">
            <div class="card-header">
                {{ information.published_date_display }}
            </div>
            <div class="card-body">
                <p class="card-text multiline">{{ information.content }}</p>
                <template v-if="information.link_url && information.link_label">
                <a :href="information.link_url" class="btn btn-primary">{{ information.link_label }}</a>
                </template>
            </div>
        </div>
    </template>
    <p v-else>現在お知らせはありません</p>
    </template>
    </section>
</template>

<script>
import InlineLoader from '@/shared/components/tool/InlineLoader';
import Information from '@/shared/models/entities/information';

export default {
    name: 'PageHome',
    components: {
        InlineLoader,
    },
    data: () => {
        return {
            loading: true,
            informations: [],
            extension: null,
        }
    },
    mounted() {
        this.loading = true;
        this.$http.get('/informations')
        .then((response) => {
            this.informations = [];
            for (let row of response.data) {
                this.informations.push(new Information(row));
            }
        })
        .finally(() => {
            this.loading = false;
        });

        this.$http.get('/informations/extension')
        .then((response) => {
            this.extension = new Information(response.data);
        })
    },
}
</script>

<style scoped>

</style>
